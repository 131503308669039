<template>
  <div
    class="me"
    :class="busy ? 'busy' : ''"
    :style="style"
    v-if="control.synopticComponent"
  >
    <div
      class="input-group"
      :style="{
        display:
          $utils.isFirefox() && parseInt($utils.navigatorVersion()) < 95
            ? 'table'
            : 'flex'
      }"
    >
      <SynopticDataValueDuration
        v-if="isDuration"
        v-model="value"
        ref="inpValue"
        :style="inputStyle"
        :disabled="
          !lastData || isDisabled || !hasWritePermission || mode == 'editor'
        "
        :showDays="showDays"
        :showSeconds="showSeconds"
        :userUnit="unitLabel || 'seconds'"
        :saveOnTab="saveOnTab"
        @focus="setFocus(true)"
        @blur="setFocus(false)"
        @keydown.enter.prevent="save"
        @keydown.tab.prevent="save"
        @keydown.delete.stop
      />
      <InputString
        v-else-if="isString"
        v-model="value"
        class="form-control text-left"
        ref="inpValue"
        :type="!hasFocus || $utils.isMobile() ? 'text' : ''"
        :style="inputStyle"
        :disabled="
          !lastData || isDisabled || !hasWritePermission || mode == 'editor'
        "
        :saveOnTab="saveOnTab"
        :maxSize="maxSize"
        @focus="setFocus(true)"
        @blur="setFocus(false)"
        @keydown.enter.prevent="save"
        @keydown.tab.prevent="save"
        @keydown.delete.stop
        @keydown.esc.prevent="reset"
      />
      <InputNumber
        v-else
        v-model="value"
        class="form-control text-right"
        ref="inpValue"
        :type="!hasFocus || $utils.isMobile() ? 'text' : ''"
        :style="inputStyle"
        :disabled="
          !lastData || isDisabled || !hasWritePermission || mode == 'editor'
        "
        :saveOnTab="saveOnTab"
        :step="inputStep"
        @focus="setFocus(true)"
        @blur="setFocus(false)"
        @keydown.enter.prevent="save"
        @keydown.tab.prevent="save"
        @keydown.delete.stop
        @keydown.esc.prevent="reset"
        @inc="onStep($event)"
      />
      <template v-if="showActionButtons">
        <div
          class="input-group-addon"
          v-if="showRestoreButton"
          :title="$t('titles.reset_value')"
          :style="iconRestoreStyle"
          v-on:click="restore(!isDuration)"
        >
          <i class="fa fa-undo"></i>
        </div>
        <div
          class="input-group-addon"
          v-if="showSaveButton"
          :title="$t('save')"
          :style="iconSaveStyle"
          v-on:click="save"
        >
          <i class="fa fa-check"></i>
        </div>
      </template>
      <SynopticDataValueTip
        :entry="lastData"
        :value="value"
        :error="error"
        :position="control.synopticComponent.commandStatus"
        :hasPendingCommand="hasPendingCommand"
      />
    </div>
  </div>
</template>

<script>
import SynopticDataValueBase from "./synoptic-data-value-base.vue";
import SynopticDataValueTip from "./synoptic-data-value-tip.vue";
import InputNumber from "@/components/input-number.vue";
import InputString from "@/components/input-string.vue";
import SynopticDataValueDuration from "@/components/synoptic/synoptic-data-value-duration.vue";

import omit from "lodash/omit";

export default {
  name: "SynopticDataValueInput",
  extends: SynopticDataValueBase,
  components: {
    SynopticDataValueTip,
    InputNumber,
    InputString,
    SynopticDataValueDuration
  },
  computed: {
    inputStyle() {
      // right now input and select are sharing same style, but keep it independent
      // if has no focus
      let r = Object.assign(
        {
          "font-size": "14pt",
          "background-color": "transparent",
          color: "inherit"
        },
        omit(
          this.controlStyle,
          "transform",
          "border",
          "border-radius",
          "background-color"
        )
      );
      r["color"] = this.fgColor;
      return r;
    },
    iconRestoreStyle() {
      let r = {
        "font-size": this.inputStyle["font-size"],
        color: this.fgColor,
        "--text-size-adjust": this.$utils.iOS() ? "35%" : "65%"
      };
      return r;
    },
    iconSaveStyle() {
      let r = {
        ...this.iconRestoreStyle,
        "--text-size-adjust": this.$utils.iOS() ? "35%" : "65%"
      };
      if (!this.isValid || !this.hasWritePermission) {
        r["color"] = "#dcdcdc";
      }
      return r;
    },
    style() {
      let r = {
        transform: `rotate(${parseInt(
          this.control.synopticComponent.rotation || 0
        )}deg)`,
        width: this.currentRect.width + "px",
        height: this.currentRect.height + "px",
        border: this.controlStyle.border,
        "border-radius": this.controlStyle["border-radius"] ?? "3px",
        "background-color": this.bgColor,
        "--text-size-adjust": this.$utils.iOS() ? "35%" : "70%"
      };
      if (this.tmp && this.tmp.style) {
        Object.assign(r, this.tmp.style);
      }
      return r;
    },
    saveOnTab() {
      if (!("saveOnTab" in this?.control?.synopticComponent)) {
        return true; // legacy does not have this attribute
      }
      return this?.control?.synopticComponent?.saveOnTab;
    },
    showDays() {
      return (
        this.isDuration &&
        /dd/i.test(this?.control?.format || this?.lastData?.custom_format || "")
      );
    },
    showSeconds() {
      return (
        this.isDuration &&
        /ss/i.test(this?.control?.format || this?.lastData?.custom_format || "")
      );
    },
    unitLabel() {
      return this?.lastData?.unity_label || "";
    },
    maxSize() {
      return this.$root.$formatter.maxInputLength(this.lastData);
    },
    inputStep() {
      if (this.isDuration || this.isString) return undefined;
      let vlr = parseInt(this.control.synopticComponent.inputStep ?? "");
      return isNaN(vlr) || vlr <= 1 ? 1 : vlr;
    }
  },
  methods: {
    setFocus(hasFocus) {
      this.hasFocus = hasFocus;
    },
    reset($e) {
      this.restore(false);
      this.setFocus(false);
      if ($e && $e.target && $e.target.blur) {
        $e.target.blur();
      }
    },
    onStep($e) {
      if (this.inputStep === undefined) return;
      this.value =
        parseFloat(this.iValue) + ($e > 0 ? this.inputStep : -this.inputStep);
    }
  }
};
</script>

<style scoped>
.me {
  z-index: inherit;
  -webkit-text-size-adjust: var(--text-size-adjust);
  -moz-text-size-adjust: var(--text-size-adjust);
  text-size-adjust: var(--text-size-adjust);
}

input,
select {
  border: 0;
  z-index: inherit;
}

.input-group .form-control {
  z-index: inherit;
  flex: 4;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.input-group {
  background: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.input-group-addon {
  background: transparent;
  border: 0;
  padding: 3px;
  color: "#696969";
  flex: 1;
  -webkit-text-size-adjust: var(--text-size-adjust);
}

.input-group-addon:hover {
  background: transparent;
  cursor: pointer;
  opacity: 0.8;
}

.busy {
  opacity: 0.5;
}

.busy:hover {
  cursor: wait;
}
</style>
