<template>
  <div
    class="form-group form-group-sm form-group-no-margin has-enphasis has-feedback"
  >
    <div class="input-group input-group-sm">
      <div class="input-group-addon" style="font-size: 80%; padding: 0 4px">
        {{ $t("test_value") }}
      </div>
      <input
        class="form-control text-center"
        id="testDataValue"
        ref="inp"
        v-model="value"
        @click.prevent.stop
        :placeholder="$t('not_persisted')"
        :type="inputType"
        data-testid="value"
      />
      <div
        class="input-group-addon btn"
        @click.prevent.stop="reset"
        data-testid="reset"
      >
        <i class="fa fa-close"></i>
      </div>
      <div
        class="input-group-addon btn hidden-sm hidden-xs"
        :class="isSimulating ? 'text-orange' : ''"
        v-on:click.stop.prevent="toggleSimulation()"
        :title="$t('simulation')"
      >
        <i class="fa fa-history"></i>
      </div>
    </div>
    <div
      class="slidecontainer"
      v-if="this.inputType != 'text' || this.inputType != 'bool'"
    >
      <input
        type="range"
        :min="slider.min"
        :max="slider.max"
        v-model="sliderValue"
        class="slider"
      />
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
export default {
  name: "TestDataValue",
  components: {},
  props: {
    dataId: {
      type: [Number, String],
      required: true,
      default: () => 0
    },
    historySimulation: {
      type: String,
      required: false,
      default: () => "auto"
    }
  },
  data() {
    return {
      colapsed: true,
      lastValue: "",
      slider: {
        min: 0,
        max: 100,
        value: 50
      }
    };
  },
  computed: {
    isSimulating() {
      if (!this.equipmentData) return false;
      let entries = this.$store.getters["history/entries"] || {};
      return ((entries[this.equipmentData.id] || {})?.samples || []).length > 0;
    },
    showHistorySimulation() {
      if (this.historySimulation == "auto") {
        return this?.equipmentData?.history_enabled || false;
      }
      return this.historySimulation;
    },
    equipmentData() {
      return (this.$store.getters["dashboard/extendedDataList"] || []).find(
        (data) => data.id == this.dataId
      );
    },
    dataType() {
      return this?.equipmentData?.type || "";
    },
    inputType() {
      return isNaN(parseInt(this.dataId)) || this.dataType == "string"
        ? "text"
        : "number";
    },
    value: {
      set(value) {
        let id = this.dataId;
        this.lastValue = value;
        if (isNaN(parseInt(id))) {
          let connId = "";
          if (/(connector_|device_)\d+/.test(this.dataId)) {
            connId = this.dataId.match(/\d+/)[0];
          } else {
            let connector =
              this.$store.getters["dashboard/dashboardEquipment"] || null;
            if (
              connector &&
              connector.base_model &&
              this.dataId.replace(/connector_/, "") != this.dataId
            ) {
              connId = connector.id;
            }
          }
          if (connId) {
            let propName = this.dataId.replace(
              /(connector|device)_[\d\_]*/,
              ""
            );
            let entry = {};
            if (/is_connected/.test(propName)) {
              entry.id = connId;
              entry.connected = parseInt(value) ? true : false;
            } else if (/has_active_alarms/.test(propName)) {
              entry.id = connId;
              entry.has_active_alarms = parseInt(value) ? true : false;
            } else {
              entry.connector_id = connId;
              entry[propName] = value;
            }
            if (entry.id) {
              this.$store.dispatch("dashboard/setConnectorValue", entry);
            } else {
              this.$store.dispatch("dashboard/setConnectorPropertyValue", {
                connector_id: connId,
                properties: entry
              });
            }
          }
        } else {
          let vlr = !isNaN(parseInt(value))
            ? this.dataType == "bool" || this.dataType == "bool"
              ? parseInt(value)
              : this.dataType == "float"
                ? parseFloat(value)
                : value
            : value;
          this.$store.dispatch("dashboard/setDataValue", {
            id: id,
            value: vlr
          });
          if (
            this.dataType != "string" &&
            this.dataType != "bool" &&
            vlr !== "" &&
            this.slider.value != parseInt(vlr)
          ) {
            this.sliderValue = parseInt(vlr);
          }
        }
      },
      get() {
        let value = this.lastValue;
        if (typeof value === "boolean") {
          value = value ? 1 : 0;
        }
        return value;
      }
    },
    sliderValue: {
      set(value) {
        if (isNaN(parseInt(value))) return;
        this.slider.value = parseInt(value);
        if (this.lastValue != this.slider.value) {
          this._updateFromSlider();
        }
      },
      get() {
        return this.slider.value ?? 50;
      }
    }
  },
  watch: {
    dataId: {
      handler(n, o) {
        if (n && o && n == o) return;
        if (this.equipmentData) {
          let value = (this?.equipmentData?.current_value || {}).value;
          if (this.dataType == "bool") {
            this.lastValue = value ? 1 : 0;
          } else if (this.dataType == "string") {
            this.lastValue = value;
          } else {
            this.lastValue =
              value !== "" && !isNaN(value) ? typeof value == 'boolean' ? (value ? 1 : 0) : parseInt(value) : "";
            this.slider.value = this.lastValue !== "" ? this.lastValue : 50;
            if (this.equipmentData.minimum_value !== "") {
              this.slider.min = parseInt(this.equipmentData.minimum_value);
            } else {
              this.slider.min = this.slider.value - (this.slider.value || 0);
            }
            if (this.equipmentData.maximum_value !== "") {
              this.slider.max = parseInt(this.equipmentData.maximum_value);
            } else {
              this.slider.max = this.slider.value + (this.slider.value || 100);
            }
          }
        } else {
          this.lastValue = "";
        }
      },
      immediate: true
    }
  },
  methods: {
    toggleSimulation() {
      if (this?.equipmentData?.id) {
        this.value = parseInt(Math.random() * 10000) / 100;
        if (this.equipmentData.history_enabled) {
          this.$store.dispatch("history/simulate", this.equipmentData.id);
        }
      }
    },
    toggle() {
      this.colapsed = !this.colapsed;
      if (!this.colapsed) {
        this.$nextTick(() => {
          if (this.$refs.inp) {
            this.$refs.inp.focus();
          }
        });
      }
    },
    reset() {
      this.lastValue = "";
      if (this?.equipmentData?.restore) {
        this.$store.dispatch("dashboard/setDataValue", {
          id: this.dataId,
          current_value: { ...this.equipmentData.restore }
        });
      } else {
        this.$store.dispatch("dashboard/setDataValue", {
          id: this.dataId,
          value: ""
        });
      }
      if (this.equipmentData.history_enabled) {
        this.$store.dispatch("history/resetData", this.equipmentData.id);
      }
      if (this.$refs.inp) {
        this.$refs.inp.focus();
      }
    }
  },
  created() {
    this._updateFromSlider = debounce(() => {
      if (isNaN(parseInt(this.slider.value))) return;
      if (this.lastValue != this.slider.value) {
        this.value = this.slider.value;
      }
    }, 300);
  }
};
</script>

<style scoped>
.me {
  margin: 0;
  padding: 0;
}

.colapsed {
  padding-bottom: 0px;
}

.clicable:hover {
  cursor: pointer;
}

.slidecontainer {
  width: 100%;
  z-index: 2;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  border-radius: 0 0 5px 5px;
}

.slider:hover {
  opacity: 1;
  z-index: 3;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  margin-top: 5px;
  appearance: none;
  width: 5px;
  height: 10px;
  background: #4e789b;
  border-radius: 0 0 3px 3px;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  margin-top: 5px;
  appearance: none;
  width: 5px;
  height: 10px;
  background: #737473;
  border-radius: 0 0 3px 3px;
  cursor: pointer;
}
</style>
