<template>
  <div
    class="status-icon"
    :style="style"
    v-if="currentState && currentState.img"
  >
    <div class="status-icon-container" ref="img">
      <!-- <img v-bind:src="currentState.img" class="status-image" /> -->
    </div>
  </div>
  <div class="status-icon" :style="style" v-else-if="mode == 'editor'">
    {{ (currentState && currentState.label) || "ImageList" }}
  </div>
</template>

<script>
import SynopticEquipmentDataControlBase from "@/components/synoptic/synoptic-equipment-data-control-base.vue";

export default {
  name: "SynopticStatusIcon",
  extends: SynopticEquipmentDataControlBase,
  data() {
    return {
      matchState: false
    };
  },
  computed: {
    isDirty() {
      return false;
    },
    currentState: function() {
      var self = this;
      let value =
        typeof this.rawValue == "boolean"
          ? this.rawValue
            ? 1
            : 0
          : this.rawValue;
      var defState = (self.items || []).find(
        (i) => String(i.state).toLowerCase() == "default"
      );
      let state =
        self.items.find(
          (i) => String(i.state) !== "" && String(i.state) === String(value)
        ) ||
        defState ||
        null;
      return state;
    },
    items() {
      if (
        !("dataSource" in this.control.synopticComponent) ||
        this.control.synopticComponent?.dataSource?.type == "local"
      ) {
        return this.control?.synopticComponent?.stateImages || [];
      } else {
        let lst = [];
        let id = this.control.synopticComponent?.dataSource?.id || 0;
        if (id) {
          let entry = this.globalLists.find((i) => i.id == id) || null;
          for (let key in entry?.items || {}) {
            let value = key in (entry.default_item || {}) ? "default" : key;
            let img = "";
            let len =
              this?.control?.synopticComponent?.stateImages?.length || 0;
            if (len) {
              let state =
                this.control.synopticComponent.stateImages.find(
                  (item) => item.state == key || item.state == value
                ) || null;
              if (state) {
                img = state.img;
              }
            }
            lst.push({
              state: value,
              backgroundColor: "transparent",
              img: img,
              label: entry.items[key]
            });
          }
        }
        return lst;
      }
    },
    globalLists() {
      return this.$root.config.references.text_lists || [];
    },
    style() {
      return {
        ...this.controlStyle,
        width: this.currentRect.width + "px",
        height: this.currentRect.height + "px"
      };
    }
  },
  watch: {
    currentState: {
      handler(n) {
        let style = this?.control?.synopticComponent?.style || {};
        let bgColor =
          !n || n.backgroundColor == "transparent"
            ? style["background-color"] || "transparent"
            : n.backgroundColor;
        this.$nextTick(() => {
          if (this.$refs.img) {
            this.$refs.img.style.backgroundColor = bgColor;
            this.$refs.img.style.backgroundImage = `url(${this.currentState.img})`;
          }
        });

        this.$emit("hasContent", n !== null);
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
/* status icon component */

.status-icon {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-icon-container {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transition: background-image 500ms ease;
}

.status-image {
  width: 100%;
  height: 100%;
  display: inline-block;
}

.status-icon-green {
  background: #28d826;
  color: #ffffff;
}

.status-icon-red {
  background: #ef3900;
  color: #ffffff;
}

.status-icon-gray {
  background: #a7a7a7;
  color: #ffffff;
}
</style>
