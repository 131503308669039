<template>
  <div class="me" v-if="control.synopticComponent" :style="style">
    <div
      class="input-group"
      v-if="items.length"
      :style="{
        display:
          $utils.isFirefox() && parseInt($utils.navigatorVersion()) < 95
            ? 'table'
            : 'flex'
      }"
    >
      <select
        class="form-control"
        ref="inpValue"
        :style="inputStyle"
        v-model="value"
        :disabled="isDisabled"
        @change="onChange"
        @focus="setFocus(true)"
        @blur="setFocus(false)"
      >
        <option v-for="(item, ix) in items" :key="ix" :value="item.state">
          {{ item.label }}
          <template v-if="control.synopticComponent.showValue">
            (
            {{ item.value }}
            <template
              v-if="control.synopticComponent.showDefault && 1 == item.value"
              >&#x2713;</template
            >
            )
          </template>
        </option>
      </select>
      <template v-if="showActionButtons">
        <div
          class="input-group-addon"
          v-if="showRestoreButton"
          :title="$t('titles.reset_value')"
          :style="iconRestoreStyle"
          v-on:click="restore(true)"
        >
          <i class="fa fa-undo"></i>
        </div>
        <div
          class="input-group-addon"
          v-if="showSaveButton"
          :title="$t('save')"
          :style="iconSaveStyle"
          v-on:click="save"
        >
          <i class="fa fa-check"></i>
        </div>
      </template>
      <SynopticDataValueTip
        :entry="lastData"
        :value="value"
        :error="error"
        :position="control.synopticComponent.commandStatus"
        :hasPendingCommand="hasPendingCommand"
      />
    </div>
  </div>
</template>

<script>
import SynopticDataValueBase from "./synoptic-data-value-base.vue";
import SynopticDataValueTip from "./synoptic-data-value-tip.vue";

import omit from "lodash/omit";

export default {
  name: "SynopticDataValueSelect",
  extends: SynopticDataValueBase,
  components: {
    SynopticDataValueTip
  },
  data() {
    return {
      matchState: false
    };
  },
  computed: {
    value: {
      set(value) {
        this.iValue = this.parseValue(value);
      },
      get() {
        return this.iValue;
      }
    },
    isValid() {
      if (!this?.lastData?.id && this.mode == "viewer") return false;
      if (this.value === "default" || this.value === "") return false;
      return true;
    },
    currentState: function() {
      var self = this;
      let value = this.value;
      var defState = (self.items || []).find(
        (i) => String(i.state).toLowerCase() == "default"
      );
      let state =
        self.items.find(
          (i) => String(i.state) !== "" && String(i.state) === String(value)
        ) ||
        defState ||
        null;
      if (state) {
        this.$emit("hasContent", true);
      }
      return state || null;
    },
    items() {
      if (
        !("dataSource" in this.control.synopticComponent) ||
        this.control.synopticComponent?.dataSource?.type == "local"
      ) {
        return this.control?.synopticComponent?.stateImages || [];
      } else {
        let lst = [];
        let id = this.control.synopticComponent?.dataSource?.id || 0;
        if (id) {
          let entry = this.globalLists.find((i) => i.id == id) || null;
          for (let key in entry?.items || {}) {
            lst.push({
              state: key,
              backgroundColor: "transparent",
              img: "",
              label: entry.items[key]
            });
          }
        }
        return lst;
      }
    },
    globalLists() {
      return this.$root.config.references.text_lists || [];
    },
    inputStyle() {
      // right now input and select are sharing same style, but keep it independent
      let r = Object.assign(
        {
          "font-size": "14pt",
          "background-color": "transparent",
          color: this.fgColor
        },
        omit(
          this.controlStyle,
          "transform",
          "border",
          "border-radius",
          "background-color"
        )
      );
      if (
        (this?.currentState?.backgroundColor || "transparent") != "transparent"
      ) {
        r["color"] = this.currentState.backgroundColor;
      }
      if (this.$utils.isFirefox() && this.$utils.navigatorVersion() < "95") {
        let svg =
          '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" class="iconify iconify--mdi"><path d="M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6l-6-6l1.41-1.42z" fill="currentColor"></path></svg>';
        r["-moz-appearance"] = "none !important";
        r["background-image"] = `url('data:image/svg+xml;utf8,${svg}')`;
        r["background-size"] = "10%";
        r["background-repeat"] = "no-repeat";
        r["background-position"] = "calc(100% - 3px) center !important";
      }
      return r;
    },
    iconRestoreStyle() {
      let r = {
        "font-size": this.inputStyle["font-size"],
        color: this.fgColor
      };
      return r;
    },
    iconSaveStyle() {
      let r = { ...this.iconRestoreStyle };
      if (!this.isValid) {
        r["color"] = "#dcdcdc";
      }
      return r;
    },
    style() {
      let r = {
        transform: `rotate(${parseInt(
          this.control.synopticComponent.rotation || 0
        )}deg)`,
        width: this.currentRect.width + "px",
        height: this.currentRect.height + "px",
        border: this.controlStyle.border,
        "border-radius": this.controlStyle["border-radius"] ?? "3px",
        "background-color": this.bgColor
      };
      if (this.tmp && this.tmp.style) {
        Object.assign(r, this.tmp.style);
      }
      return r;
    }
  },
  methods: {
    setFocus(value) {
      this.hasFocus = value;
    },
    onChange() {
      if (this?.control?.synopticComponent?.saveButton) return;
      this.save();
    }
  }
};
</script>

<style scoped>
.me {
  z-index: inherit;
}

input,
select {
  border: 0;
  z-index: inherit;
}

.input-group .form-control {
  z-index: inherit;
  flex: 4;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.input-group {
  background: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.input-group-addon {
  background: transparent;
  border: 0;
  padding: 3px;
  color: "#696969";
  flex: 1;
}

.input-group-addon:hover {
  background: transparent;
  cursor: pointer;
  opacity: 0.8;
}

.disabled:hover {
  cursor: not-allowed;
}
</style>
