import { render, staticRenderFns } from "./synoptic-control.vue?vue&type=template&id=8ade6412&scoped=true&"
import script from "./synoptic-control.vue?vue&type=script&lang=js&"
export * from "./synoptic-control.vue?vue&type=script&lang=js&"
import style0 from "./synoptic-control.vue?vue&type=style&index=0&id=8ade6412&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ade6412",
  null
  
)

export default component.exports