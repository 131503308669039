<template>
  <div class="me" v-if="control.synopticComponent" :style="style">
    <div
      :class="{
        clicable: hasWritePermission && this.lastData && this.lastData.enabled,
        disabled: isDisabled
      }"
      v-on:click.prevent.stop="onClick"
      :style="inputStyle"
    >
      <i class="fa fa-check-square-o" v-if="isChecked"></i>
      <i class="fa fa-square-o" v-else></i>
      <span class="text">{{ label }}</span>
    </div>
    <SynopticDataValueTip
      :entry="lastData"
      :value="value"
      :error="error"
      :position="control.synopticComponent.commandStatus"
      :hasPendingCommand="hasPendingCommand"
    />
  </div>
</template>

<script>
import SynopticDataValueBase from "./synoptic-data-value-base.vue";
import SynopticDataValueTip from "./synoptic-data-value-tip.vue";
export default {
  name: "SynopticDataValueCheckbox",
  extends: SynopticDataValueBase,
  components: {
    SynopticDataValueTip
  },
  computed: {
    isChecked() {
      return (
        parseInt(this.value) ==
        parseInt(this?.control?.synopticComponent?.values?.checked || 0)
      );
    },
    label() {
      return this.control.synopticComponent.label || "";
    },
    localError() {
      if (
        this.values.length &&
        this?.lastData?.current_value &&
        this.values.indexOf(Number(this.value)) == -1
      ) {
        return this.$t("invalid_value") + " (" + this.value + ")";
      }
      return "";
    },
    values() {
      return Object.values(
        this?.control?.synopticComponent?.values || {}
      ).map((i) => Number(i));
    },
    inputStyle() {
      if (!this.isValid && this.control.synopticComponent.errorStyle) {
        return {
          color: this.control.synopticComponent.errorStyle.color ?? "black"
        };
      }
      return {};
    },
    style() {
      return {
        ...this.controlStyle,
        width: this.currentRect.width + "px",
        height: this.currentRect.height + "px"
      };
    }
  },
  methods: {
    onClick() {
      if (this.busy || this.isDisabled) return;
      this.value = this.isChecked
        ? this.control.synopticComponent.values.unchecked
        : this.control.synopticComponent.values.checked;
      this.save();
    }
  }
};
</script>

<style scoped>
.me {
  z-index: inherit;
  display: table;
  vertical-align: middle;
  height: inherit;
  width: inherit;
}

.me > div {
  display: table-cell;
  vertical-align: middle;
  width: inherit;
}

.text {
  font-size: 95%;
  padding-left: 10px;
}

.clicable:hover {
  opacity: 0.6;
  cursor: pointer;
}

.disabled:hover {
  cursor: not-allowed;
}
</style>
